import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions } from "@angular/http";
import { Path } from "../path";

@Injectable()
export class UserService {
  readonly rootUrl = Path.URL;

  constructor(private http: HttpClient) { }

  login(username, password) {
    var data = ({ "Username": username, "Password": password });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Autentification/Login', data, { headers: requestHeader });
  }

  logout() {
    return this.http.get(this.rootUrl + '/api/Autentification/Logout');
  }
}
